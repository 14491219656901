import { default as BounceERC1155WithSignRaw } from './BounceERC1155WithSign.json';
import { default as BounceERC20Raw } from './BounceERC20.json';
import { default as BounceERC721WithSignRaw } from './BounceERC721WithSign.json';
import { default as BounceEnglishAuctionNFTRaw } from './BounceEnglishAuctionNFT.json';
import { default as BounceFixedSwapNFTRaw } from './BounceFixedSwapNFT.json';
import { default as BounceErc721Raw } from './BounceErc721.json';
import { default as BounceErc1155Raw } from './BounceErc1155.json';
import { default as BounceNFTFactoryV2Raw } from './BounceNFTFactoryV2.json';
import { AbiItem } from 'web3-utils';

export const BounceERC1155WithSign: AbiItem = (BounceERC1155WithSignRaw.abi as unknown) as AbiItem;
export const BounceERC20: AbiItem = (BounceERC20Raw.abi as unknown) as AbiItem;
export const BounceERC721WithSign: AbiItem = (BounceERC721WithSignRaw.abi as unknown) as AbiItem;
export const BounceEnglishAuctionNFT: AbiItem = (BounceEnglishAuctionNFTRaw.abi as unknown) as AbiItem;
export const BounceFixedSwapNFT: AbiItem = (BounceFixedSwapNFTRaw.abi as unknown) as AbiItem;
export const BoucneErc721Bytecode = BounceErc721Raw.bytecode;
export const BounceErc721: AbiItem = (BounceErc721Raw.abi as unknown) as AbiItem;
export const BoucneErc1155Bytecode = BounceErc1155Raw.bytecode;
export const BounceErc1155: AbiItem = (BounceErc1155Raw.abi as unknown) as AbiItem;
export const BounceNFTFactoryV2: AbiItem = (BounceNFTFactoryV2Raw.abi as unknown) as AbiItem;
